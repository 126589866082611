import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import photographyProjectsData from "../../assets/data/photographyProjects.json";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import styles from "./Photography.module.scss";

const Photography = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setProjects(photographyProjectsData);
  }, []);

  return (
    <div className={styles.pageWrapper}>
      <Header />
      <div className={styles.container}>
        <h1 className={styles.container__title}>Photography</h1>
        <h3 className={styles.container__subtitle}>
          Explore my photography projects below.
        </h3>

        <div className={styles.projects}>
          {projects.map((project) => (
            <div key={project.id} className={styles.projectCard}>
              <Link
                to={`/photography/${project.id}`}
                className={styles.projectCard}
              >
                <div
                  className={styles.projectCard__image}
                  style={{
                    backgroundImage: `url(${project.cover}`,
                  }}
                >
                  <div className={styles.projectCard__overlay}>
                    <h3 className={styles.projectCard__title}>
                      {project.title}
                    </h3>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Photography;
