import React from "react";
import { useParams, Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import styles from "./ProjectDetails.module.scss";

const ProjectDetails = ({ projectsData, categoryRoute }) => {
  const { id } = useParams();
  const project = projectsData.find((proj) => proj.id === parseInt(id));

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div className={styles.pageWrapper}>
      <Header />
      <div className={styles.container}>
        <div className={styles.details}>
          <div className={styles.details__block}>
            <h3>Project</h3>
            <p>{project.title}</p>
          </div>
          <div className={styles.details__block}>
            <h3>Client</h3>
            <p>{project.client}</p>
          </div>
          <div className={styles.details__block}>
            <h3>Year</h3>
            <p>{project.year}</p>
          </div>
          <div className={styles.details__block}>
            <h3>Category</h3>
            <p>{project.category}</p>
          </div>
          <div className={styles.details__block}>
            <h3>Technologies</h3>
            <p>{project.technologies}</p>
          </div>
          <div className={styles.details__block}>
            <h3>Brief</h3>
            <p>{project.brief}</p>
          </div>
          {project.link && project.link !== "" && (
            <div className={styles.details__block}>
              <h3>Link</h3>
              <a
                href={project.link}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.linkText}
              >
                Click to visit the page
              </a>
            </div>
          )}
        </div>

        <div className={styles.media}>
          {project.projectImages && project.projectImages.length > 0 && (
            <div className={styles.media__gallery}>
              {project.projectImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${project.title} preview ${index + 1}`}
                  className={styles.media__image}
                />
              ))}
            </div>
          )}
          {project.projectVideos && project.projectVideos.length > 0 && (
            <div className={styles.media__gallery}>
              {project.projectVideos.map((video, index) => (
                <video
                  key={index}
                  controls
                  autoPlay
                  loop
                  muted
                  src={video}
                  className={styles.media__video}
                />
              ))}
            </div>
          )}
        </div>

        <div className={styles.backToProjects}>
          <Link
            to={`/${categoryRoute}`}
            className={styles.backToProjects__link}
          >
            ← Back to projects
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectDetails;
