import React, { useState } from "react";
import styles from "./Contact.module.scss";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";

emailjs.init(process.env.REACT_APP_PUBLIC_KEY);

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, message } = formData;

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (response) => {
          console.log("Email sent succesfully!", response);
          setFormData({ name: "", email: "", message: "" });
          toast.success("Email sent successfully!");
        },
        (error) => {
          console.error("Error al enviar el mensaje", error);
          toast.error("There was an error, please try again later.");
        }
      );
  };

  return (
    <section id="contact" className={styles.contact}>
      <div className={styles.contactContent}>
        <div className={styles.contactText}>
          <h2 className={styles.title}>Contact me</h2>
          <p className={styles.subtitle}>Let's talk!</p>
          <p>
            I'm currently available. Feel free to reach out to me for
            collaborations or any questions! I’d love to hear from you.
          </p>

          <h4>
            <a href="mailto:hola@macarenapaz.com">hola@macarenapaz.com</a>
          </h4>
          <h4>
            <a
              href="https://www.linkedin.com/in/macarena-paz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn · Macarena Paz
            </a>
          </h4>
          <h4>
            <a
              href="https://github.com/misspaz"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github · misspaz
            </a>
          </h4>
        </div>
        <form className={styles.contactForm} onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            value={formData.name}
            placeholder="Name"
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            placeholder="Email"
            onChange={handleInputChange}
            required
          />
          <textarea
            name="message"
            value={formData.message}
            placeholder="Message"
            onChange={handleInputChange}
            required
          ></textarea>
          <button type="submit">Send</button>
        </form>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Contact;
