import React from "react";
import styles from "./Skills.module.scss";
import {
  FaReact,
  FaNodeJs,
  FaHtml5,
  FaCss3Alt,
  FaSass,
  FaGitAlt,
  FaFigma,
  FaJsSquare,
} from "react-icons/fa";
import { SiNextdotjs, SiPython, SiMysql, SiAdobe } from "react-icons/si";

const Skills = () => {
  return (
    <section id="skills" className={styles.skills}>
      <h2 className={styles.skills__title}>Skills, languages and frameworks</h2>
      <div className={styles.skills__list}>
        <div className={styles.skills__item}>
          <FaJsSquare size={40} />
          <p>JavaScript</p>
        </div>
        <div className={styles.skills__item}>
          <FaReact size={40} />
          <p>React</p>
        </div>
        <div className={styles.skills__item}>
          <SiNextdotjs size={40} />
          <p>Next.js</p>
        </div>

        <div className={styles.skills__item}>
          <FaNodeJs size={40} />
          <p>Node.js</p>
        </div>
        <div className={styles.skills__item}>
          <FaHtml5 size={40} />
          <p>HTML5</p>
        </div>
        <div className={styles.skills__item}>
          <FaCss3Alt size={40} />
          <p>CSS3</p>
        </div>
        <div className={styles.skills__item}>
          <FaSass size={40} />
          <p>SASS</p>
        </div>
        <div className={styles.skills__item}>
          <FaGitAlt size={40} />
          <p>Git</p>
        </div>
        <div className={styles.skills__item}>
          <SiMysql size={40} />
          <p>MySQL</p>
        </div>
        <div className={styles.skills__item}>
          <SiPython size={40} />
          <p>Python</p>
        </div>
        <div className={styles.skills__item}>
          <FaFigma size={40} />
          <p>Figma</p>
        </div>
        <div className={styles.skills__item}>
          <SiAdobe size={40} />
          <p>Adobe Suite</p>
        </div>
      </div>
    </section>
  );
};

export default Skills;
