import React from "react";
import styles from "./CV.module.scss";
import { FaChevronDown } from "react-icons/fa";

const CV = () => {
  return (
    <section id="cv" className={styles.cv}>
      <h2>Download my CV</h2>

      <img
        className={styles.cv__image}
        src="/assets/images/laptop.png"
        alt="Macarena Paz CV laptop"
      ></img>

      <div className={styles.cv__buttons}>
        <button>
          <a href="/assets/files/CV_Macarena_Paz_2025_web_ES.pdf" download>
            Spanish version <FaChevronDown />
          </a>
        </button>
        <button>
          <a href="/assets/files/CV_Macarena_Paz_2025_EN.pdf" download>
            English version <FaChevronDown />
          </a>
        </button>
      </div>
    </section>
  );
};

export default CV;
