import React from "react";
import styles from "./Categories.module.scss";
import { Link } from "react-router-dom";

const Categories = () => {
  return (
    <section id="categories" className={styles.categories}>
      <h2 className={styles.categories__title}>Categories</h2>
      <div className={styles.category__list}>
        <Link to="/fullstack-development" className={styles.category}>
          <div
            className={styles.category__image}
            style={{
              backgroundImage:
                "url('/assets/images/fullstack-development-cover-smarttown-by-Macarena-Paz.jpg')",
            }}
          >
            <div className={styles.category__text}>
              <h3>FullStack Development</h3>
            </div>
          </div>
        </Link>
        <Link to="/graphic-design" className={styles.category}>
          <div
            className={styles.category__image}
            style={{
              backgroundImage:
                "url('/assets/images/graphic-design-cover-vitae-by-Macarena-Paz.jpg')",
            }}
          >
            <div className={styles.category__text}>
              <h3>Graphic design & UX/UI</h3>
            </div>
          </div>
        </Link>
        <Link to="/photography" className={styles.category}>
          <div
            className={styles.category__image}
            style={{
              backgroundImage:
                "url('/assets/images/photography-cover-nonexistentdesert-by-Macarena-Paz.jpg')",
            }}
          >
            <div className={styles.category__text}>
              <h3>Photography</h3>
            </div>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default Categories;
