import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { animateScroll as scroll, scroller } from "react-scroll";
import styles from "./Header.module.scss";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash) {
      scroller.scrollTo(hash, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [location]);

  return (
    <header className={styles.header}>
      <nav className={styles.navbar}>
        <div>
          <Link to="/">
            <img
              className={styles.logo}
              src="/assets/images/Logo_Macarena_Paz_1.png"
              alt="Macarena Paz logotype"
            />
          </Link>
        </div>
        <button
          className={styles.menuToggle}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          ☰
        </button>

        <ul
          className={`${styles.navlinks} ${
            menuOpen ? styles.navlinksOpen : ""
          }`}
        >
          <li>
            <Link to="/#categories" onClick={() => setMenuOpen(false)}>
              Work
            </Link>
          </li>
          <li>
            <Link to="/#cv" onClick={() => setMenuOpen(false)}>
              CV
            </Link>
          </li>
          <li>
            <Link to="/#contact" onClick={() => setMenuOpen(false)}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
