import React from "react";
import styles from "./Home.module.scss";
import Categories from "../Categories/Categories";
import Skills from "../Skills/Skills";
import CV from "../CV/CV";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const Home = () => {
  return (
    <>
      <div>
        <div id="about" className={styles.about}>
          <Header />
          <div className={styles.about__content}>
            <h2 className={styles.about__content__text}>
              Hi! I'm{" "}
              <span className={styles.about__content__text__name}>
                Macarena Paz,
              </span>{" "}
              creative soul, <br />
              based in Madrid
            </h2>

            <div className={styles.about__content__roles}>
              <div className={styles.about__content__roles__group}>
                <h2 className={styles.about__content__roles__rol}>
                  FULLSTACK DEVELOPMENT
                </h2>
                <h2 className={styles.about__content__roles__rol}>
                  GRAPHIC DESIGN
                </h2>
                <h2 className={styles.about__content__roles__rol}>UX/UI</h2>
              </div>
              <div className={styles.about__content__roles__group}>
                <h2 className={styles.about__content__roles__rol}>
                  PHOTOGRAPHY
                </h2>
                <h2 className={styles.about__content__roles__rol}>
                  VIDEOGRAPHY
                </h2>
              </div>
            </div>
          </div>
          <video
            className={styles.backgroundVideo}
            autoPlay
            muted
            loop
            preload="auto"
          >
            <source src="/assets/videos/background-1.mp4" type="video/mp4" />
            Your browser does not support the video element.
          </video>
        </div>
      </div>
      <Categories id="categories" />
      <Skills id="skills" />
      <CV id="cv" />
      <Contact id="contact" />
      <Footer />
    </>
  );
};

export default Home;
