import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import FullStackDevelopment from "./components/FullStackDevelopment/FullStackDevelopment";
import GraphicDesign from "./components/GraphicDesign/GraphicDesign";
import Photography from "./components/Photography/Photography";
import ProjectDetails from "./components/ProjectDetails/ProjectDetails";
import fullstackProjectsData from "./assets/data/fullstackProjects.json";
import graphicDesignProjectsData from "./assets/data/graphicDesignProjects.json";
import photographyProjectsData from "./assets/data/photographyProjects.json";

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/fullstack-development"
            element={<FullStackDevelopment />}
          />
          <Route path="/graphic-design" element={<GraphicDesign />} />
          <Route
            path="/project/:id"
            element={
              <ProjectDetails
                projectsData={fullstackProjectsData}
                categoryRoute="fullstack-development"
              />
            }
          />
          <Route
            path="/graphic-design-project/:id"
            element={
              <ProjectDetails
                projectsData={graphicDesignProjectsData}
                categoryRoute="graphic-design"
              />
            }
          />
          <Route path="/photography" element={<Photography />} />
          <Route
            path="/photography/:id"
            element={
              <ProjectDetails
                projectsData={photographyProjectsData}
                categoryRoute="photography"
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
